<span [innerHtml]="copyrightText()"></span>

iXsystems, Inc. dba

<a
  target="_blank"
  title="iXsystems, Inc. dba TrueNAS."
  ixTest="copyright"
  [href]="targetHref()"
>
  TrueNAS
</a>
